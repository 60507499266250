import { PageProps } from 'gatsby';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import CrewMember from '../../components/CrewMember';
import GreenStripe from '../../components/GreenStripe';
import Headline from '../../components/headline/Headline';
import { Image } from '../../components/Image';
import { Section } from '../../components/Section';
import { SEO } from '../../components/Seo';
import { Submenu } from '../../components/Submenu';
import { AllMarkdownRemarkQueryResult } from '../../interfaces/AllMarkdownRemark';
import { CrewJsonNode } from '../../interfaces/CrewJsonNode';
import { MarkdownFileNode } from '../../interfaces/MarkdownFileNode';
import { NodeCrew } from '../../interfaces/NodeCrew';
import { QueryResponseAllCrewJson } from '../../interfaces/QueryResponseAllCrewJson';
import { allCrewJson } from '../../queries/AllCrewJson';
import { allMarkdownRemark } from '../../queries/AllMarkdownRemark';
import { Container, Description } from '../../styles/CommonStyledComponents';
import { decodeUri } from '../../utils/DecodeUri';
import { findMarkdownFile } from '../../utils/FindMarkdownFile';
import { getJsonByPathname } from '../../utils/GetJsonByPathname';
import { getKindergartenByPathname } from '../../utils/GetKindergartenByPathname';

const About = (props: PageProps) => {
  const data: AllMarkdownRemarkQueryResult<MarkdownFileNode> | undefined = allMarkdownRemark<
    AllMarkdownRemarkQueryResult<MarkdownFileNode>
  >();
  const markdownAboutFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'about');
  const markdownAboutCrewFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'about-crew');
  const allCrewJsonData = allCrewJson();

  return (
    <>
      <SEO title={decodeUri(getKindergartenByPathname(props.location.pathname))} />
      <GreenStripe />
      <Submenu location={props.location} orderCollection={['O nas', 'Kadra']}></Submenu>
      <Section id="O nas">
        <Headline arrowColor="arrowGreen" title="O nas"></Headline>
        <Container>
          <Image loading="eager" fadeIn={false} fluid={markdownAboutFile?.frontmatter?.image?.childImageSharp.fluid} />
          <Description
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(markdownAboutFile?.html || ''),
            }}
          ></Description>
        </Container>
      </Section>
      <Section id="Kadra">
        <Headline arrowColor="arrowGreen" title="Kadra przedszkola Nutka"></Headline>
        <Container>
          <Description
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(markdownAboutCrewFile?.html || ''),
            }}
          ></Description>
        </Container>
      </Section>
      <Section>
        <Headline arrowColor="arrowGreen" title="Kadra"></Headline>
        <Container>
          {getJsonByPathname<CrewJsonNode, QueryResponseAllCrewJson>(
            allCrewJsonData,
            props.location.pathname,
            'allCrewJson',
          )?.crew?.map((person: NodeCrew, i: number) => (
            <CrewMember
              key={i}
              name={person.name}
              position={person.position}
              description={person.description}
              image={person.image}
              location={props.location}
            ></CrewMember>
          ))}
        </Container>
      </Section>
    </>
  );
};

export default About;
