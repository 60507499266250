import { FixedObject, FluidObject } from 'gatsby-image';
import React from 'react';
import { StyledImage } from '../styles/CommonStyledComponents';

type ImageProps = {
  loading?: string;
  fadeIn?: boolean;
  floated?: boolean;
  fluid?: FluidObject | FluidObject[];
  fixed?: FixedObject | FixedObject[];
};

export const Image = (props: ImageProps) => {
  if (props.floated == false) {
    return (
      <div>
        <StyledImage
          loading={(props.loading as any) || 'lazy'}
          fadeIn={props.fadeIn || true}
          fluid={props.fluid as FluidObject | FluidObject[]}
        />
      </div>
    );
  } else {
    return <StyledImage fluid={props.fluid as FluidObject | FluidObject[]} />;
  }
};
