import { graphql, useStaticQuery } from 'gatsby';

export const allMarkdownRemark = <T>(): T => {
  return useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            images {
              image {
                childImageSharp {
                  smallImage: fluid(maxWidth: 400, maxHeight: 300, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  bigImage: fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            collection {
              collectionName
            }
          }
          html
          parent {
            ... on File {
              name
              relativeDirectory
            }
          }
        }
      }
    }
  `);
};
