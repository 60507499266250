import { AllMarkdownRemark } from '../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../interfaces/MarkdownFileNode';
import { getKindergartenByPathname } from './GetKindergartenByPathname';

export function findMarkdownFile<T extends MarkdownFileNode>(
  allMarkdownRemark: AllMarkdownRemark<T>,
  pathname: string,
  markdownType: string,
): T | undefined {
  return allMarkdownRemark.nodes.find(
    (node: T) =>
      node.parent.name === getKindergartenByPathname(pathname) && node.parent.relativeDirectory === markdownType,
  );
}
