import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import styled from 'styled-components';

import { LocationProps } from '../interfaces';
import { encodeUri } from '../utils/EncodeUri';
import { removePolishSigns } from '../utils/RemovePolishSigns';
import { theme } from '../utils/Theme';

interface ParentsToolboxMenuProps {
  location: LocationProps;
  orderCollection: string[];
}

const MenuList = styled.ul`
  font-family: 'Verdana', 'Geneva', 'sans-serif';
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  a {
    padding: 8px 20px 8px 20px;
    margin: 0px;
    background-color: ${theme.colors.darkGreen};
    text-transform: uppercase;
    color: ${theme.colors.white};
    text-decoration: none;
    :hover {
      background-color: ${theme.colors.green};
      text-shadow: ${theme.colors.dark} 1px 1px 5px;
    }
  }
  ${theme.mediaQuerySizes.tablet} {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 0 auto;
    text-align: center;
  }
  ${theme.mediaQuerySizes.smallScreen} {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
`;

const getAnchorLink = (node: string, index: number, location: LocationProps) => {
  if (node != '') {
    return (
      <AnchorLink key={index} to={location.pathname + '#' + encodeUri(removePolishSigns(node))}>
        {node}
      </AnchorLink>
    );
  }
};

export const Submenu = ({ location, orderCollection }: ParentsToolboxMenuProps) => {
  return (
    <MenuList>{orderCollection?.map((node: string, index: number) => getAnchorLink(node, index, location))}</MenuList>
  );
};
